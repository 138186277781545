@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@media screen and (max-width: 768px) {
    :root {
        font-size: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    :root {
        font-size: 12px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    :root {
        font-size: 14px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
    :root {
        font-size: 16px;
    }
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
    :root {
        font-size: 20px;
    }
} */

html {
    font-size: 62.5%;

    font-family: 'Noto Sans KR', sans-serif;
}
body,
#root {
    width: 100vw;
    /* height: 100vh; */
    background: #f5f9ff;
    margin: 0; /* 기본 마진 제거 */
    padding: 0; /* 기본 패딩 제거 */
}

#App {
    width: 100vw;
    /* height: 100vh; */
    background: white;
    overflow: auto;
    position: relative;
    display: flex;
    justify-content: center;
}
@layer base {
    body {
        @apply font-sans;
    }
}

@font-face {
    font-family: 'Noto Sans KR DemiLight';
    src: url('./assets/fonts/NotoSansCJKkr-DemiLight.otf');
}
@font-face {
    font-family: 'Noto Sans KR Regular';
    src: url('./assets/fonts/NotoSansCJKkr-Regular.otf');
}
@font-face {
    font-family: 'Noto Sans KR Medium';
    src: url('./assets/fonts/NotoSansCJKkr-Medium.otf');
}
@font-face {
    font-family: 'Noto Sans KR Bold';
    src: url('./assets/fonts/NotoSansCJKkr-Bold.otf');
}
